<template>
	<div class="app-container">
		<el-dialog :title="$t('menu.image_manager')" :visible="isShowDialog" @close="onClose" width="80%">
			<div class="row">
				<div class="col-12">
					<el-button icon="el-icon-top" size="medium" :loading="buttonloading" v-show="!topFolder" v-if="permissionList.includes(permission.back)" @click="backToParent();"></el-button>
					<el-button icon="el-icon-refresh" size="medium" :loading="buttonloading" @click="refreshPage();"></el-button>
					
					<el-popover placement="right" width="380" style="margin-left:10px;" v-model="uploadvisible" v-if="permissionList.includes(permission.upload)">
						<el-form id="uploadForm" @submit.prevent="submit">
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_image_name')}}</template>
								<b-form-input v-model="createList.name"></b-form-input>
							</b-form-group>
							
							<b-form-group label-cols="12">
								<el-upload ref="uploadAdd" class="upload-demo" list-type="picture" drag :auto-upload="false" :headers="headersData" :data="postData" :action="uploadImageURL" :on-success="uploadSuccess" :limit="1" accept="image/jpeg,image/jpg,image/png">
									<i class="el-icon-upload"></i>
									<div class="el-upload__text">{{$t('msg.msg_upload_1')}}<em>{{$t('msg.msg_upload_2')}}</em></div>
									<div class="el-upload__tip" slot="tip">{{$t('msg.msg_upload_tips')}}</div>
								</el-upload>
							</b-form-group>
							
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_security')}}</template>
								<b-form-input v-model="createList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
							</b-form-group>
							
							<b-form-group label-cols="12">
								<el-button type="primary" @click="uploadImage">{{$t('button.submit')}}</el-button>
								<el-button @click="uploadvisible = false">{{$t('button.close')}}</el-button>
							</b-form-group>
						</el-form>
						
						<el-button icon="el-icon-upload" type="primary" size="medium" :loading="buttonloading" slot="reference"></el-button>
					</el-popover>
					<el-popover placement="bottom" width="200" style="margin-left:10px; margin-right: 10px;" v-model="popvisible" v-if="permissionList.includes(permission.add)">
						<el-form id="createForm" @submit.prevent="submit">
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_folder_name')}}</template>
								<b-form-input v-model="createList.folder_name"></b-form-input>
							</b-form-group>
							
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_security')}}</template>
								<b-form-input v-model="createList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
							</b-form-group>
							
							<b-form-group label-cols="12">
								<el-button type="primary" @click="createFolder">{{$t('button.submit')}}</el-button>
								<el-button @click="popvisible = false">{{$t('button.close')}}</el-button>
							</b-form-group>
						</el-form>
						<el-button icon="el-icon-folder-add" size="medium" :loading="buttonloading" slot="reference"></el-button>
					</el-popover>
					<el-button icon="el-icon-delete" type="danger" size="medium" :loading="buttonloading" @click="deleteRow" v-if="permissionList.includes(permission.delete)"></el-button>
				</div>

				<div class="col-12">
					<el-divider></el-divider>
					
					<b-form-checkbox-group v-model="dataList.urlList" v-if="galleryList.length > 0">
						<div class="row">
							<div v-for="(item,index) in galleryList" :key="index" class="col-12 col-sm-6 col-md-4 col-lg-3">
								<div v-if="item.type == 'file'" class="text-center">
									<div @click="selectImage(item.path);">
										<el-image :src="item.path" class="img-thumbnail" style="height:160px;"></el-image>
									</div><br/>
									<b-form-checkbox :value="item.path">{{item.name}}</b-form-checkbox>
								</div>
								
								<div v-else class="text-center">
									<div @click="nextDirectory(item.path);">
									<i class="el-icon-folder text-info" style="font-size:165px"></i>
									</div><br/>
									<b-form-checkbox :value="item.path">{{item.name}}</b-form-checkbox>
								</div>
							</div>
						</div>
					</b-form-checkbox-group>
					
					<div v-else class="text-center">
						{{$t('el.table.emptyText')}}
					</div>
					
					<pagination v-show="imageTotal > 0" :total="imageTotal" :page.sync="imageListQuery.page" :limit.sync="imageListQuery.limit" @pagination="imagePagination"/>
				</div>
				<div class="col-12 text-center">
					<el-button @click="onClose" :loading="buttonloading">{{$t('button.close')}}</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {getLocalStorage} from '@/system/store/localstorage';
import {postMethod} from '@/system/model/post/post';
import {getDAES, getEAES} from '@/system/library/security';
import pagination from '@/components/pagination';
import config from '@/system/engine/config';

export default{
	components: { pagination },
	props: {
		isShowDialog:{
			type:Boolean,
			default:false,
        }
    },data(){
		return{
			loading: true,
			buttonloading: false,
			postData:{
				data: '',
				language: '',
			},searchForm:{
				id: '',
			},
			searchData:{
				pagination: 1,
				limit: 10,
				url: ''
			},
			imageTotal: 0,
			imageListQuery: {
				page: 1,
				limit: 10
			},
			dataList:{
				urlList: [],
				security: ''
			},
			createList:{
				url: '',
				name: '',
				folder_name: '',
				security: ''
			},permission:{
                ajaxTable:'MPYFXM',
                back:'6IU8PA',
                add:'NOO1S6',
                DBadd:'CKR7U5',
                upload:'X65NU7',
                DBupload:'PNP1J5',
                delete:'O7FGYN',
                DBdelete:'S5DGXQ'
            },
			topFolder: false,
            permissionList:[],
			openStatus: this.openDialog,
			galleryList: [],
			languageList: [],
			popvisible: false,
			uploadvisible: false,
			selectImageData:{
				index: '',
				path: ''
			},headersData:{
				XSPOT: ''
			},
			uploadImageURL: ''
		}
	},methods:{
		initial(){
			if(this.permissionList.includes(this.permission.ajaxTable)){
				this.clearFolderList();
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod('gallery/lists/ajaxTable',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.topFolder = data.isTop;
						self.galleryList = data.galleryList;
						self.imageTotal = parseInt(data.total_number);
						self.imageListQuery.page = parseInt(data.pagination);
						self.imageListQuery.limit = parseInt(data.limit);
						self.buttonloading = false;
						self.loading = false;
					}
				});
			}
		},onClose(){
			this.searchData.pagination = 1;
			this.searchData.limit = 10;
			this.searchData.url = '';
			
			this.dataList.security = '';
			this.dataList.urlList = [];
			
			this.$emit('dialogData', false);
		},onOpen(index){
			this.selectImageData.index = index;
			this.initial();
		},imagePagination(){
			this.searchData.pagination = this.imageListQuery.page;
			this.searchData.limit = this.imageListQuery.limit;
			this.initial();
		},deleteRow(){
			if(this.permissionList.includes(this.permission.DBdelete)){
				this.buttonloading = true;
				var text = '';
				var self = this;
				
				this.$prompt(this.$t('msg.msg_delete'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.dataList.security = value;

					this.postData.data = '';
					this.postData.data = getEAES(JSON.stringify(this.dataList));
					
					var result = postMethod('gallery/lists/DBdelete',this.postData);

					result.then(function(value){
						var data = JSON.parse(getDAES(value.data));

						if(value.valid){
							self.$message({
								message: data.returnMsg,
								type: 'success'
							});
							
							self.initial();
						}else{					
							data.returnMsg.forEach(function (value){
								text+= value+"<br/>";
							});
							
							self.$method.popupErrorMessage(self,text);
						}
						
						self.buttonloading = false;
					});
				}).catch(() => {
					this.buttonloading = false;          
				});
			}
		},nextDirectory(path){
			this.searchData.url = path;
			this.createList.url = path;
			this.initial();
		},refreshPage(){
			this.dataList.urlList = [];
			this.clearFolderList();
			this.initial();
		},backToParent(){
			if(this.permissionList.includes(this.permission.back)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod('gallery/lists/back',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.searchData.url = data.current_url;
						self.createList.url = data.current_url;
						self.topFolder = data.isTop;
						self.galleryList = data.galleryList;
						self.imageTotal = parseInt(data.total_number);
						self.imageListQuery.page = parseInt(data.pagination);
						self.imageListQuery.limit = parseInt(data.limit);
						self.buttonloading = false;
						self.loading = false;
					}
				});
			}
		},createFolder(){
			if(this.permissionList.includes(this.permission.DBadd)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.createList));
				var text = '';
				var self = this;
				var result = postMethod('gallery/lists/DBadd',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.$message({
							message: data.returnMsg,
							type: 'success'
						});

						self.initial();
					}else{
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						self.$method.popupErrorMessage(self,text);
					}
					
					self.clearFolderList();
					self.buttonloading = false;
					self.loading = false;
				});
			}
		},clearFolderList(){
			this.createList.folder_name = '';
			this.createList.name = '';
			this.createList.security = '';
		},uploadImage(){
			if(this.permissionList.includes(this.permission.DBupload)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.createList));
				
				this.$refs.uploadAdd.submit();
			}
		},uploadSuccess(value){
			var data = JSON.parse(getDAES(value.data));
			var text = '';
			if(value.valid){
				this.$message({
					message: this.$t('msg.msg_upload_success'),
					type: 'success'
				});
				
				this.initial();
				this.uploadvisible = false;
			}else{
				this.errors = data.returnMsg;
						
				this.errors.forEach(function (value){
					text+= value+"<br/>";
				});
				
				this.$method.popupErrorMessage(this, text);
			}
			
			this.$refs.uploadAdd.clearFiles();
			this.buttonloading = false;
			this.loading = false;
		},selectImage(path){
			this.searchData.pagination = 1;
			this.searchData.limit = 10;
			this.searchData.url = '';
			
			this.dataList.security = '';
			this.dataList.urlList = [];
			
			this.clearFolderList();
			
			this.selectImageData.path = path;
			
			this.$emit('getData', this.selectImageData);
		}
	},created:function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.headersData.XSPOT = getLocalStorage('auth_sign_token');
		this.uploadImageURL = config.url+'gallery/lists/DBupload';
		this.postData.language = currentLang;
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
		this.languageList = JSON.parse(getLocalStorage('languageList'));
	}
}
</script>